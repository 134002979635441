import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Página no encontrada" />
    <h1>Ups!</h1>
    <p>¡Lo sentimos! Parece que no logramos encontrar la página que estás buscando. Puedes ir a nuestra página de inicio o contactarnos a través de nuestro formulario de contacto.</p>
  </Layout>
)

export default NotFoundPage
